import React from "react";

import Layout from "../components/layout-header-footer/layout.js";
import SEO from "../components/seo.js";
import BlogContainer from "../components/blog-components/blog-container.js"

import "../components/global.css"

const BlogsList = () => (
  <Layout>
    <SEO title="Blogs" />
    <BlogContainer/>
  </Layout>
)

export default BlogsList;
