import React from 'react';
import { Link } from 'gatsby';
import './blog-card.css'

const BlogCard = ({title, date, imageUrl, description, articleUrl}) => {

    return (
        <article className="blog-card">
            <h2>{title}</h2>
            <p>{date}</p>
            <img alt="article cover" src={imageUrl}/>
            <p>{description}</p>
            <Link to={articleUrl}>Keep Reading</Link>
        </article>
        )
}

export default BlogCard;