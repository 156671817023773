import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import BlogCard from './blog-card.js';
import './blog-container.css';

const BlogContainer = () => {

    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark {
              edges {
                node {
                  id
                  excerpt(pruneLength: 250)
                  fields {
                    slug
                  }
                  frontmatter {
                    date
                    title
                    featuredImage {
                      childImageSharp {
                        fluid(maxWidth: 400, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }          
    `)

    const populateCards = () => {
        const articles = data.allMarkdownRemark.edges.map(article => {
            return <BlogCard
                key={article.node.id}
                title={article.node.frontmatter.title}
                date={article.node.frontmatter.date}
                imageUrl={article.node.frontmatter.featuredImage.childImageSharp.fluid.src}
                description={article.node.excerpt}
                articleUrl={article.node.fields.slug}
            />
        })
        return articles
    }

    return (
        <section className="blogs">
        <h1>Posts</h1>
        {populateCards()}
        </section>  
    )
}

export default BlogContainer;